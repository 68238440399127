import { render, staticRenderFns } from "./CollectItem.vue?vue&type=template&id=f34c1326&scoped=true"
import script from "./CollectItem.vue?vue&type=script&lang=js"
export * from "./CollectItem.vue?vue&type=script&lang=js"
import style0 from "./CollectItem.vue?vue&type=style&index=0&id=f34c1326&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f34c1326",
  null
  
)

export default component.exports
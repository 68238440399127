import { render, staticRenderFns } from "./Collect.vue?vue&type=template&id=da6f1edc&scoped=true"
import script from "./Collect.vue?vue&type=script&lang=js"
export * from "./Collect.vue?vue&type=script&lang=js"
import style0 from "./Collect.vue?vue&type=style&index=0&id=da6f1edc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da6f1edc",
  null
  
)

export default component.exports